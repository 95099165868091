import React from 'react';

import Header from '../../components/enfusion/Header';
import Footer from '../../components/enfusion/Footer';
// import EventFooter from '../../components/enfusion/Footer/EventFooter';
import { useAppContext } from '../../util/context';

import EventHeader from './header';
import SideBar from './sidebar';

const EventLayout = ({ pageProps = {}, children }) => {
    const context = useAppContext();
    context.toggleDarkMode(false);
    context.toggleHeaderTheme('bg-white');

    const { title, options, link, acf_post_event } = pageProps;

    return (
        <>
            <Header {...pageProps} />

            <main className="text-purple-midnight">
                <EventHeader
                    title={acf_post_event?.eventTitle}
                    subtitle={acf_post_event?.eventSubTitle}
                    // date={` ${getMonth} ${getDay}, ${getYear}`}
                    eventType={acf_post_event?.eventType}
                    register={acf_post_event?.eventLink}
                />

                <div className="container pb-16">
                    <div className="justify-between lg:flex">
                        <div className="w-full lg:max-w-[224px]">
                            <SideBar
                                title={acf_post_event?.eventTitle}
                                url={link}
                                freetext={acf_post_event?.eventSidebarFreeText}
                                eventLocation={acf_post_event?.eventLocation}
                            />
                        </div>
                        <div className="w-full lg:max-w-[736px] pl-0 xl:pl-0 lg:pl-6">
                            <div>{children}</div>
                        </div>
                    </div>
                </div>
            </main>

            {/* <EventFooter {...pageProps} /> */}

            <Footer {...pageProps} />
        </>
    );
};

export default EventLayout;
