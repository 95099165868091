import React, { useState } from 'react';

import Header from '../../components/enfusion/Header';
import Footer from '../../components/enfusion/Footer';
import NextNavLink from '../../components/util/NextNavLink';
import { useAppContext } from '../../util/context';

import SidebarLayoutHeader from './header';
import SideBar from './sidebar';

const SidebarLayout = ({ pageProps = {}, children }) => {
    const context = useAppContext();
    context.toggleDarkMode(false);
    context.toggleHeaderTheme('bg-white');

    const {
        title,
        date,
        options,
        mediaContactHide,
        link,
        contentType,
        relatedPosts,
        mediaContact,
        categories
    } = pageProps;
    const [rPosts, setRelatedPosts] = useState(relatedPosts.slice(0, 4));
    return (
        <>
            <Header {...pageProps} />

            <main className="text-purple-midnight">
                <SidebarLayoutHeader
                    title={title}
                    date={date}
                    breadcrumbs={pageProps.seo?.breadcrumbs}
                    contentType={contentType}
                />
                <div className="container pb-16">
                    <div className="justify-between">
                    <div className="w-full lg:max-w-[766px] pl-0 xl:pl-0 lg:pl-6">
                            <div>{children}</div>
                        </div>
                        <div className="w-full lg:max-w-[224px]">
                            <SideBar
                                title={title}
                                media={
                                    mediaContact?.name ||
                                    mediaContact?.email ||
                                    mediaContact?.phone ||
                                    mediaContact?.company
                                        ? mediaContact
                                        : options?.mediaContactSettings
                                              ?.contact &&
                                          options?.mediaContactSettings
                                              ?.contact[0]
                                }
                                isMedia={mediaContactHide}
                                url={link}
                            />
                        </div>
                        
                    </div>

                    {categories?.nodes?.length > 0 && (
                        <>
                            <p className="mt-10 mb-4 font-medium md:mb-6 label text-purple">
                                Related Topics
                            </p>
                            <div className="flex space-x-2 ">
                                {categories &&
                                    categories.nodes.map((item, index) => (
                                        <NextNavLink
                                            href={`/insights?filter=${item?.slug}`}
                                            key={index}
                                        >
                                            <a
                                                className="px-5 py-2 text-opacity-50 transition-all hover:text-opacity-100 bg-gray-18 rounded-20 text-purple-midnight focus:outline-none focus:ring-0 hover:bg-gray-16"
                                                dangerouslySetInnerHTML={{
                                                    __html: item?.name
                                                }}
                                            />
                                        </NextNavLink>
                                    ))}
                            </div>
                        </>
                    )}

                    {rPosts?.length > 0 && (
                        <>
                            <hr className="my-16 border-t md:my-24 border-purple-midnight border-opacity-20 wow fadeInUp" />

                            <p className="mb-4 font-medium md:mb-6 label text-purple">
                                Related Posts
                            </p>
                            <h4 className="mb-12 md:mb-16 md:h3 h2">
                                Explore more.
                            </h4>

                            <div className="grid grid-cols-12 gap-8">
                                {rPosts.map((item, index) => (
                                    <div
                                        key={index}
                                        className="lg:col-span-3 md:col-span-6 col-span-full"
                                    >
                                        <div
                                            className="mb-4 bg-center bg-cover aspect-w-1 aspect-h-1"
                                            style={{
                                                backgroundImage: `url(${item?.thumbnail})`
                                            }}
                                        />
                                        <p className="mb-1 font-medium label label--small">
                                            {item?.categories.map((cat, i) => (
                                                <span key={i}>
                                                    {i !== 0 ? ', ' : ''}
                                                    {cat?.name}
                                                </span>
                                            ))}
                                        </p>
                                        {item?.date && (
                                            <p className="mb-4 font-medium opacity-50 label label--small">
                                                {getDate(item?.date)}
                                            </p>
                                        )}
                                        <div
                                            className="lead lead--large"
                                            dangerouslySetInnerHTML={{
                                                __html: item?.title
                                            }}
                                        />
                                        <NextNavLink href={item?.permalink}>
                                            <a className="inline-flex items-center mt-4 font-medium text-purple hover:text-purple-midnight lead icon-circle">
                                                Read More
                                                <i className="inline-block en-long-arrow text-32 leading-18 relative z-[1] ml-4"></i>
                                            </a>
                                        </NextNavLink>
                                    </div>
                                ))}
                            </div>

                            <div className="items-center justify-center hidden my-20 mt-32 lg:flex">
                                <a
                                    href="#__next"
                                    className="inline-flex items-center font-medium transform -rotate-90 lead icon-circle"
                                >
                                    <span className="relative flex items-center h-4 ml-4">
                                        <i
                                            className={`inline-block en-long-arrow text-32 leading-18 relative z-[1] transition text-purple-midnight`}
                                        />

                                        <svg
                                            width="32"
                                            height="32"
                                            className="absolute -right-3 -top-2 icon-circle__circle"
                                        >
                                            <circle
                                                cx="16"
                                                cy="16"
                                                r="15"
                                                className="icon-circle__base"
                                            />
                                            <circle
                                                cx="16"
                                                cy="16"
                                                r="15"
                                                style={{
                                                    stroke: '#1E1A34'
                                                }}
                                                className="icon-circle__fill"
                                            />
                                        </svg>
                                    </span>
                                </a>
                            </div>
                        </>
                    )}
                </div>
            </main>

            <Footer {...pageProps} />
        </>
    );
};

function getDate(date) {
    let d = new Date(date);
    var month = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ];
    let getYear = d.getFullYear();
    let getMonth = d.getMonth();
    getMonth = month[getMonth];
    let getDay = d.getDate();
    return (
        <p className="mb-4 font-medium opacity-50 label label--small">
            {getMonth}.{getDay}.{getYear}
        </p>
    );
}
export default SidebarLayout;
