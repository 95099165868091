import React, { useEffect, useState } from 'react';

import Header from '../components/enfusion/Header';
import Footer from '../components/enfusion/Footer';
import EventFooter from '../components/enfusion/Footer/EventFooter';
import { useAppContext } from '../util/context';

const Post = ({ pageProps = {}, children }) => {
    const { acf_post_event } = pageProps;
    const [isClient, setIsClient] = useState(false);
    useEffect(() => {
        setIsClient(true);
    },[])
    const pageScripts = pageProps?.perPageFields?.customJavascript && pageProps.perPageFields.customJavascript.length ? (
        <script type="text/javascript" dangerouslySetInnerHTML={{ __html: pageProps.perPageFields.customJavascript }} />
    ) : null;

    const context = useAppContext();
    
    return (
        <>
            <Header {...pageProps} />

            <main className="text-purple-midnight">{children}</main>
            {acf_post_event?.enableEvent ? (
                <EventFooter {...pageProps} />
            ) : (
                <Footer {...pageProps} />
            )}
            {pageScripts}
        </>
    );
};

export default Post;
