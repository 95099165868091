import React, { useEffect, useState } from 'react';
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton
} from 'react-share';
const SideBar = ({ title, media, isMedia, url, mediaContact }) => {
    const [copyState, setCopyState] = useState('Copy');

    const [pageURL, setPageURL] = useState(0);
    useEffect(() => {
        setPageURL(window.location.href);
    });
    function copy(e) {
        e.preventDefault();
        var dummy = document.createElement('input'),
            text = window.location.href;

        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);
        setCopyState('Copied!');
        setTimeout(() => {
            setCopyState('Copy');
        }, 2000);
    }
    return (
        <>
            <p className="pb-5 mb-5 font-medium border-b label label--small border-purple-midnight border-opacity-20 wow fadeInUp">
                Share
            </p>
            <div className="flex items-center justify-between mb-16 wow fadeInUp">
                <div className="relative flex items-center group">
                    <div className="absolute left-1/2 transform -translate-x-1/2 bg-purple-midnight rounded-[3px] text-12 text-white leading-[12px] p-1 px-2 -bottom-6 group-hover:opacity-100 opacity-0 transition-all">
                        {copyState}
                    </div>
                    <i
                        className="transition-all cursor-pointer en-link-bold text-20 text-purple-midnight opacity-40 hover:opacity-100"
                        onClick={copy}
                    ></i>
                </div>

                <EmailShareButton
                    className="h-5"
                    title={title}
                    url={url || pageURL}
                >
                    <i className="transition-all en-mail text-20 text-purple-midnight opacity-40 hover:opacity-100"></i>
                </EmailShareButton>
                <LinkedinShareButton
                    className="h-5"
                    title={title}
                    url={`https://www.linkedin.com/sharing/share-offsite/?url=${
                        url || pageURL
                    }`}
                    source="Enfusion"
                >
                    <i className="transition-all en-linkedin text-20 text-purple-midnight opacity-40 hover:opacity-100"></i>
                </LinkedinShareButton>
                <TwitterShareButton
                    className="h-5"
                    title={title}
                    url={url || pageURL}
                >
                    <i className="transition-all en-twitter text-20 text-purple-midnight opacity-40 hover:opacity-100"></i>
                </TwitterShareButton>
                <FacebookShareButton
                    className="h-5"
                    title={title}
                    url={url || pageURL}
                >
                    <i className="transition-all en-facebook text-20 text-purple-midnight opacity-40 hover:opacity-100"></i>
                </FacebookShareButton>
            </div>
            <div className="mb-10 lg:mb-0">
                {isMedia !== true && getMedia(mediaContact || media)}
            </div>
        </>
    );
};
function getMedia(media) {
    if (media?.name || media?.email || media?.phone || media?.company) {
        return (
            <>
                <p className="pb-5 mb-5 font-medium border-b label label--small border-purple-midnight border-opacity-20 wow fadeInUp">
                    Media Contact
                </p>
                {media?.name && (
                    <p className="mb-2 lead lead--large">{media?.name}</p>
                )}
                {media?.position && (
                    <p className="mb-2 lead text-purple">{media?.position}</p>
                )}
                {media?.company && (
                    <>
                        <p className="mb-2 label label--small">
                            {media?.company}
                        </p>
                        <div className="w-8 my-5 border-t border-purple-midnight border-opacity-20"></div>
                    </>
                )}

                {media?.email && (
                    <div
                        className="inline-block mb-4 lead text-purple-midnight opacity-70 hover:opacity-100"
                        dangerouslySetInnerHTML={{ __html: media?.email }}
                    />
                )}
                {media?.phone && (
                    <a
                        href={`tel:${media?.phone}`}
                        className="inline-block pb-1 mb-4 border-b lead text-purple-midnight opacity-70 hover:opacity-100"
                    >
                        {media?.phone}
                    </a>
                )}
            </>
        );
    }
}
export default SideBar;
