import React, { useEffect } from 'react';

import Breadcrumb from '../../components/enfusion/Header/Breadcrumb';
import NextNavLink from '../../components/util/NextNavLink';

const SidebarLayoutHeader = ({ title, date, breadcrumbs, contentType }) => {
    let d = new Date(date);
    var month = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ];
    let getYear = d.getFullYear();
    let getMonth = d.getMonth();
    getMonth = month[getMonth];
    let getDay = d.getDate();

    function getBackButtonName(contentType) {
        if (contentType === 'awards') {
            return 'Awards';
        } else if (contentType === 'news') {
            return 'In The News';
        } else if (contentType === 'post') {
            return 'Insights';
        } else if (contentType === 'events') {
            return 'Upcoming Events';
        } else {
            return 'Home';
        }
    }
    function getBackButtonLink(contentType) {
        if (contentType === 'awards') {
            return '/awards';
        } else if (contentType === 'news') {
            return '/news';
        } else if (contentType === 'post') {
            return '/insights';
        } else if (contentType === 'events') {
            return '/upcoming-events';
        } else {
            return '/';
        }
    }

    return (
        <>
            <div className="md:pt-36 pt-28">
                <div className="container">
                    <NextNavLink href={getBackButtonLink(contentType)}>
                        <a className="flex items-center pl-3 font-medium text-purple-midnight lead icon-circle">
                            <span className="relative flex items-center h-4 mr-4 transform rotate-180">
                                <i className="inline-block en-long-arrow text-32 leading-18 relative z-[1]"></i>

                                <svg
                                    width="32"
                                    height="32"
                                    className="absolute -right-3 -top-2 icon-circle__circle"
                                >
                                    <circle
                                        cx="16"
                                        cy="16"
                                        r="15"
                                        className="icon-circle__base"
                                    ></circle>
                                    <circle
                                        cx="16"
                                        cy="16"
                                        r="15"
                                        className="icon-circle__fill icon-circle__fill--dark"
                                    ></circle>
                                </svg>
                            </span>
                            Back to {getBackButtonName(contentType)}
                        </a>
                    </NextNavLink>
                    <div className="mt-12 md:mt-16">
                        <Breadcrumb breadcrumbs={breadcrumbs} />
                    </div>
                    {title && (
                        <h1 className="my-5 md:my-8 h2 wow fadeInUp">
                            {title}
                        </h1>
                    )}
                    <p className="mb-16 font-medium opacity-50 label text-purple-midnight wow fadeInUp lg:mb-0">
                        {getMonth}.{getDay}.{getYear}
                    </p>
                    <hr className="hidden my-16 border-t border-purple-midnight border-opacity-20 wow fadeInUp lg:block" />
                </div>
            </div>
        </>
    );
};

export default SidebarLayoutHeader;
