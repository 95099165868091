import React, { useEffect, useState } from 'react';
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton
} from 'react-share';
const SideBar = ({ title, url, freetext, eventLocation }) => {
    const [copyState, setCopyState] = useState('Copy');

    const [pageURL, setPageURL] = useState(0);
    useEffect(() => {
        setPageURL(window.location.href);
    });
    function copy(e) {
        e.preventDefault();
        var dummy = document.createElement('input'),
            text = window.location.href;

        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);
        setCopyState('Copied!');
        setTimeout(() => {
            setCopyState('Copy');
        }, 2000);
    }
    function formatDate(date) {
        let d = new Date(date);
        var month = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];
        let getYear = d.getFullYear();
        let getMonth = d.getMonth();
        getMonth = month[getMonth];
        let getDay = d.getDate();
        return `${getMonth} ${getDay}, ${getYear}`;
    }

    return (
        <>
            <p className="pb-5 mb-5 font-medium border-b label label--small border-purple-midnight border-opacity-20 wow fadeInUp">
                Share
            </p>
            <div className="flex items-center justify-between mb-16 wow fadeInUp">
                <div className="relative flex items-center group ">
                    <div className="absolute left-1/2 transform -translate-x-1/2 bg-purple-midnight rounded-[3px] text-12 text-white leading-[12px] p-1 px-2 -bottom-6 group-hover:opacity-100 opacity-0 transition-all">
                        {copyState}
                    </div>
                    <i
                        className="transition-all cursor-pointer en-link-bold text-20 text-purple-midnight opacity-40 hover:opacity-100"
                        onClick={copy}
                    ></i>
                </div>

                <EmailShareButton
                    className="h-5"
                    title={title}
                    url={url || pageURL}
                >
                    <i className="transition-all en-mail text-20 text-purple-midnight opacity-40 hover:opacity-100"></i>
                </EmailShareButton>
                <LinkedinShareButton
                    className="h-5"
                    title={title}
                    url={`https://www.linkedin.com/sharing/share-offsite/?url=${
                        url || pageURL
                    }`}
                    source="Enfusion"
                >
                    <i className="transition-all en-linkedin text-20 text-purple-midnight opacity-40 hover:opacity-100"></i>
                </LinkedinShareButton>
                <TwitterShareButton
                    className="h-5"
                    title={title}
                    url={url || pageURL}
                >
                    <i className="transition-all en-twitter text-20 text-purple-midnight opacity-40 hover:opacity-100"></i>
                </TwitterShareButton>
                <FacebookShareButton
                    className="h-5"
                    title={title}
                    url={url || pageURL}
                >
                    <i className="transition-all en-facebook text-20 text-purple-midnight opacity-40 hover:opacity-100"></i>
                </FacebookShareButton>
            </div>
            <p className="pb-5 mb-5 font-medium border-b label label--small border-purple-midnight border-opacity-20 wow fadeInUp">
                Event Details
            </p>
            <div className="mb-16 wow fadeInUp lg:mb-0">
                {freetext && (
                    <>
                        <p className="mb-2 lead lead--large">Date & Time</p>
                        <div
                            className="lead opacity-70"
                            dangerouslySetInnerHTML={{ __html: freetext }}
                        />
                        {/* {times &&
                            times.map((item, index) => (
                                <>
                                    {index !== 0 ? (
                                        <div className="my-5 border-t border-purple-midnight border-opacity-20 max-w-[32px]"></div>
                                    ) : (
                                        ''
                                    )}
                                    <p key={index} className="lead opacity-70">
                                        {formatDate(item?.eventDate)}
                                        <br />
                                        {item?.eventStartTime &&
                                            `${item?.eventStartTime} - ${item?.eventEndTime}`}{' '}
                                        {item?.timezone && item?.timezone}
                                    </p>
                                </>
                            ))} */}
                    </>
                )}
                {/* {eventStartTime && (
                    <>
                        <div className="my-5 border-t border-purple-midnight border-opacity-20 max-w-[32px]"></div>
                        <p className="mb-2 lead lead--large">Time</p>
                        <p className="uppercase lead opacity-70">
                            {eventStartTime} - {eventEndTime}{' '}
                            {timezone && timezone}
                        </p>
                    </>
                )} */}
                {eventLocation && (
                    <>
                        {freetext && (
                            <div className="my-5 border-t border-purple-midnight border-opacity-20 max-w-[32px]"></div>
                        )}
                        <p className="mb-2 lead lead--large">Location</p>
                        <div
                            className=" lead opacity-70"
                            dangerouslySetInnerHTML={{ __html: eventLocation }}
                        />
                    </>
                )}
            </div>
        </>
    );
};
export default SideBar;
