import React from 'react';

export function ExampleContainerShortcode({ children }) {
    return (
        <div>
            {children}
        </div>
    );
}

export function ExampleColumnsShortcode({ children }) {
    return (
        <div className={`grid grid-cols-${children.length}`}>
            {children}
        </div>
    );
}

export function ExampleColumnShortcode({ children, content }) {
    return (
        <div>
            {content}
        </div>
    );
}