import React, { useState } from 'react';
import NextNavLink from '../../../util/NextNavLink';

export const Event = ({ meta, content, background, cta, eventType }) => {
     
    let fluid = false;
    let light_dark = 'Light'; 
     

    return (
        <div className={`overflow-hidden  relative  wow fadeInUp`}>
            <div
                className={`bg-cover py-2 ${!fluid ? 'container' : ''}`}
                style={
                    background && fluid
                        ? { backgroundImage: `url(${background})` }
                        : {}
                }
            >
                <div
                    className={`lg:flex items-center justify-between  bg-cover  py-10 lg:text-left text-center ${
                        !fluid ? 'lg:px-24 px-8 md:py-28' : 'container md:py-36'
                    }`}
                    style={
                        background && !fluid
                            ? {
                                  backgroundImage: `url(${background})`
                              }
                            : {}
                    }
                >
                    {eventType && (
                        <h3
                            className={`lg:mb-0 mb-8 wow fadeInUp ${
                                light_dark === 'Light'
                                    ? 'text-white'
                                    : 'text-purple-midnight'
                            }`}
                        >
                           Join the {eventType}.
                        </h3>
                    )}
                    {cta && (
                        <NextNavLink href={cta.url} target={cta.target}>
                            <a
                                className={`${
                                    light_dark === 'Light'
                                        ? 'btn--white'
                                        : 'btn--midnight'
                                } btn md:btn--lg wow fadeInUp`}
                            >
                                {cta.title}
                            </a>
                        </NextNavLink>
                    )}
                </div>
            </div>
        </div>
    );
};
export default Event;
